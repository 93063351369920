const routes = [
  {
    path: "/",
    component: () => import("@/views/appMainPage"),
    name: "Mainpage",
  },
  {
    path: "/beauty",
    component: () => import("@/views/appProgramPage"),
    name: "beauty",
    props: false,
  },
  {
    path: "/shop",
    component: () => import("@/layouts/magazineProgram"),
    name: "shop",
    props: false,
  },
  {
    path: "/kids",
    component: () => import("@/layouts/childProgram"),
    name: "kids",
    props: false,
  },
  {
    path: "/cafe",
    component: () => import("@/layouts/caffeProgram"),
    name: "cafe",
    props: false,
  },
  {
    path: "/:success",
    component: () => import("@/views/appMainPage"),
    name: "SuccessPay",
    props: { success: true },
  },
];

export default routes;
