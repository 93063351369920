import axios from "axios";
import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

const store = new Vuex.Store({

    state: {
        url: null,
        policy: null,
        program: null

    },
    mutations: {
        SET_URL(state, url) {
            state.url = url;
        },
        SET_POLICY_OBJECT(state, policy) {
            state.policy = policy;
        },
        SET_PROGRAM(state, payload) {
            state.program = payload
        }
    },
    actions: {
        BUY_POLICY({commit}, data) {
            return axios({
                method: "POST",
                url: `https://api.vskcorp.ru/iid/buy/`,
                data: data
            })
                .then((response) => {
                    commit("SET_URL")
                    return response;
                })
                .catch((error) => {
                    return error;
                });
        },
        BUY_READY_POLICY({commit}, data) {
            return axios({
                method: "POST",
                url: `https://api.vskcorp.ru/iid/buyreadypolicy/`,
                data: data
            })
                .then((response) => {
                    commit("SET_URL")
                    return response;
                })
                .catch((error) => {
                    return error;
                });
        },
        GET_DRAFT({commit}, data) {
            return axios({
                method: "POST",
                url: `https://api.vskcorp.ru/iid/getdraft/`,
                data: data
            })
                .then((response) => {
                    commit("SET_POLICY_OBJECT")
                    return response;
                })
                .catch((error) => {
                    return error;
                });
        },
        SEND_MAIL({commit}, data) {
            return axios({
                method: "POST",
                url: `https://api.vskcorp.ru/iid/mail/`,
                data: data
            })
                .then((response) => {
                    commit("SET_URL")
                    return response;
                })
                .catch((error) => {
                    return error;
                });
        },

        setProgram({commit}, payload){
            return new Promise((resolve, reject) => {
                if (!payload) reject()
                commit('SET_PROGRAM', payload)
                resolve()
            })
        }

    },


    getters: {
        GET_POLICY_OBJECT(state) {
            return state.policy
        },
        program: (state) => state.program
    }
})

export default store
